import React from 'react'
import { useParams } from 'react-router-dom'
import Arcos from '../Interfaces/IArcos';

export default function TextoTitulo(prop) {

    const {arco , capitulo} = useParams()
    const arc = parseInt(arco , 10);
    const cap = parseInt(capitulo , 10);
    
    return (
    // <div >Uma tarde que uma baita de uma {Arcos["arcos"][arc]["capitulo"][cap]["nome"]}</div>
    <div className="conteudo-controle-nome-texto">{Arcos["arcos"][arc]["capitulo"][cap]["nome"]}</div>

  )
}
