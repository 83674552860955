
const Arcos = {
    numeroArcos: 4,
    ultimoArcosLegivel: 1,
    arcos:{
        1:{
            id:1 ,
            nome: "Ricota & Risoto" ,
            primeiroCap: 0,
            ultimoCap: 1,
            numeroCapitulos: 2 ,
            capitulo:{
                0:{
                    id: 0 ,
                    numeroPaginas: 59 ,
                    nome: "Prólogo",
                },
                1:{
                    id: 1 ,
                    numeroPaginas: 116 ,
                    nome: "Serrarilho",
                }
                // 3:{
                //     id: 3 ,
                //     numeroPaginas: 4 ,
                //     nome: "Noite",
                // },
                // 4:{
                //     id: 3 ,
                //     numeroPaginas: 4 ,
                //     nome: "Dia",
                // },
                // 5:{
                //     id: 3 ,
                //     numeroPaginas: 4 ,
                //     nome: "Lua",
                // },
                // 6:{
                //     id: 3 ,
                //     numeroPaginas: 4 ,
                //     nome: "Noite4",
                // },
                // 7:{
                //     id: 3 ,
                //     numeroPaginas: 4 ,
                //     nome: "Noite5",
                // },
                // 8:{
                //     id: 3 ,
                //     numeroPaginas: 4 ,
                //     nome: "Noite6",
                // },
                },
        },
        2:{
            id:2 ,
            nome: "? ? ?",
            primeiroCap: 1 ,
            ultimoCap: 1 ,
            numeroCapitulos: 1 ,
            capitulo:{
                1:{
                    id: 1 ,
                    numeroPaginas: 1 ,
                    nome: "Em breve...",
                },
                // 5:{
                //     id: 5 ,
                //     numeroPaginas: 3 ,
                //     nome: "Rivendell",
                // },
                // 6:{
                //     id: 6 ,
                //     numeroPaginas: 3 ,
                //     nome: "brandwine",
                // },
                // 7:{
                //     id: 7 ,
                //     numeroPaginas: 3 ,
                //     nome: "Shire",
                // },
                // 8:{
                //     id: 8 ,
                //     numeroPaginas: 3 ,
                //     nome: "Gondor",
                // },
            },
        },
        3:{
            id:3 ,
            nome: "? ? ?",
            primeiroCap: 1 ,
            ultimoCap: 1 ,
            numeroCapitulos: 1 ,
            capitulo:{
                1:{
                    id: 1 ,
                    numeroPaginas: 1 ,
                    nome: "Em breve...",
                },
                },
        },
        4:{
            id:3 ,
            nome: "? ? ?",
            primeiroCap: 1 ,
            ultimoCap: 1 ,
            numeroCapitulos: 2 ,
            capitulo:{
                1:{
                    id: 1 ,
                    numeroPaginas: 1 ,
                    nome: "Quem sabe...",
                },
                },
        },
    },
}
    
    export default Arcos;
